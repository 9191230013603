import React from "react";
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../css/footer.css';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer">
                <div className="footer-links">
                    <h2>Links:</h2>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Projects</a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div>

                <div className="footer-social">
    <h2>Contact:</h2>
    <a href="mailto:jafarhussein6@gmail.com" target="_blank" rel="noopener noreferrer">
        <EmailIcon fontSize="large" style={{ color: '#ff5733' }} />
        <span>Jafarhussein6@gmail.com</span>
    </a>

    <a href="https://www.linkedin.com/in/jafar-hussein-b76998160" target="_blank" rel="noopener noreferrer">
        <LinkedInIcon fontSize="large" style={{ color: '#ff5733' }} />
        <span>Jafar Hussein</span>
    </a>
</div>

            </div>
            <p>© 2024 Jafar</p>
        </footer>
    );
}

export default Footer;

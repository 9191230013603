import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import '../css/navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen); // Toggle the menu open/close state
        if (!isOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    };

    return (
        <div className="navbar-container">
            <header className="header">
                <nav className={`navbar ${isOpen ? 'active' : ''}`}>
                    <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                    <ul className={`nav-menu ${isOpen ? 'active' : ''}`}>
                        <li className="nav-item"><Link to="/" className="nav-link" onClick={toggleMenu}>Home</Link></li>
                        <li className="nav-item"><Link to="/projects" className="nav-link" onClick={toggleMenu}>Projects</Link></li>
                        <li className="nav-item"><Link to="/about" className="nav-link" onClick={toggleMenu}>About</Link></li>
                        <li className="nav-item"><Link to="/contact" className="nav-link" onClick={toggleMenu}>Contact</Link></li>
                    </ul>
                </nav>
            </header>
        </div>
    );
}

export default Navbar;

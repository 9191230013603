import React from 'react';
import '../css/about.css';
import ConstructionIcon from '@mui/icons-material/Construction';
import LanguageIcon from '@mui/icons-material/Language';

const About = () => {
  return (
    <div className="about-container">
      {/* Left content section */}
      <section className="about-content">
        <div className="about-info">
          <header>
            <h1>About</h1>
          </header>
          <img
            src="https://images.unsplash.com/photo-1622712092088-7e0f8e3e6b9d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fHx8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
            alt="about"
            className="about-image"
          />
          <div className="text-section">
            <p>Hi, I'm Jafar Hussein. I'm a developer and I love to code.</p>
            <p>
              I have been coding for 5 years now and I have experience in various
              programming languages.
            </p>
          </div>
        </div>
      </section>

      {/* Right black section */}
      <div className="right-section">
        <section className="knowledge-content">
          <header className="header-row">
            <ConstructionIcon className='icon' />
            
          </header>
          <ul>
            <li>JavaScript
              <div className="skill-bar">
                <div className="skill-level" style={{width: '90%'}}></div>
              </div>
            </li>
            <li>React
              <div className="skill-bar">
                <div className="skill-level" style={{width: '80%'}}></div>
              </div>
            </li>
            <li>Java
              <div className="skill-bar">
                <div className="skill-level" style={{width: '100%'}}></div>
              </div>
            </li>
            <li>Spring Boot
              <div className="skill-bar">
                <div className="skill-level" style={{width: '100%'}}></div>
              </div>
            </li>
            <li>Unit Testing
              <div className="skill-bar">
                <div className="skill-level" style={{width: '100%'}}></div>
              </div>
            </li>
            <li>AWS
              <div className="skill-bar">
                <div className="skill-level" style={{width: '90%'}}></div>
              </div>
            </li>
            <li>HTML & CSS
              <div className="skill-bar">
                <div className="skill-level" style={{width: '90%'}}></div>
              </div>
            </li>
            <li>MySQL
              <div className="skill-bar">
                <div className="skill-level" style={{width: '100%'}}></div>
              </div>
            </li>
            <li>MongoDB
              <div className="skill-bar">
                <div className="skill-level" style={{width: '80%'}}></div>
              </div>
            </li>
            <li>Git
              <div className="skill-bar">
                <div className="skill-level" style={{width: '80%'}}></div>
              </div>
            </li>
            <li>REST APIs
              <div className="skill-bar">
                <div className="skill-level" style={{width: '90%'}}></div>
              </div>
            </li>
          </ul>
        </section>
        <div className="language-section">
            <header className="header-row">
              <LanguageIcon className='icon' />
            </header>
            <ul>
              <li>English</li>
              <li>Swedish</li>
            </ul>
          </div>
      </div>
    </div>
  );
};

export default About;

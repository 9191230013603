import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './components/layout/footer.js';
import Navbar from './components/layout/navbar.js';
import React from 'react';
import StartPage from './components/javascript/startpage.js';
import About from './components/javascript/about.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

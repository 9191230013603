import React from 'react';
import image from '../images/startimage.jpg';
import '../css/startpage.css';

const StartPage = () => {
    return (
        <div className="startpage">
        <section className='hero'>
            <div className="image-hero">
                <img src={image} alt="hero" className='image'/>
                <div className='hero-text'>
                <h1>Hi, I'm <span>Jafar Hussein</span></h1>
                <p className='title-par'>And I'm a Developer</p>
                <button>Projects</button>
            </div>
            </div>
        </section>
        </div>
    );
    }

export default StartPage;